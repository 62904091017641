



import { Component, Mixins } from 'vue-property-decorator'
import Explanation from '@/components/organisms/study/Explanation.vue'
import Contents2 from '@/views/student/study/Contents2.vue'

@Component({
  components: {
    Explanation,
  },
})
export default class Explanation4 extends Mixins(Contents2) {}
