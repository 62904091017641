import { render, staticRenderFns } from "./Explanation4.vue?vue&type=template&id=58b68da8&scoped=true&lang=pug&"
import script from "./Explanation4.vue?vue&type=script&lang=ts&"
export * from "./Explanation4.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b68da8",
  null
  
)

export default component.exports